import { useMemo } from 'react'

/**
 * Returns the first preferred video mime type supported by the browser.
 * Credits: https://github.com/apivideo/api.video-progressive-upload-demo/blob/main/utils/getPreferredVideoMimeType.ts
 *
 * Removed codecs checks as there is a whitelist on the backend side:
 * https://makipeople.slack.com/archives/C02JKK8H4CB/p1645194756489089?thread_ts=1645191923.609549&cid=C02JKK8H4CB
 */
function getPreferredMediaMimeType(type: 'audio' | 'video') {
  const fileExtensions = ['mp4', 'webm']

  for (const fileExtension of fileExtensions) {
    const mimeType = `${type}/${fileExtension}`
    if (MediaRecorder.isTypeSupported(mimeType)) {
      return { name: mimeType, extension: fileExtension }
    }
  }
  return
}

export function usePreferredMediaMimeType(type: 'audio' | 'video') {
  return useMemo(() => getPreferredMediaMimeType(type), [type])
}
