const ACCENT_MAP: { [key: string]: string } = {
  a: 'aàáâãäåāąă',
  c: 'cçćčĉ',
  d: 'dđď',
  e: 'eèéêëēęěĕė',
  g: 'gğĝģ',
  h: 'hĥħ',
  i: 'iìíîïīĩĭįı',
  j: 'jĵ',
  k: 'kķĸ',
  l: 'lłľĺļŀ',
  n: 'nñńňņŉŋ',
  o: 'oòóôõöøōőŏ',
  r: 'rŕřŗ',
  s: 'sßśšşŝș',
  t: 'tťţŧț',
  u: 'uùúûüūůűŭũų',
  w: 'wŵ',
  y: 'yÿýŷ',
  z: 'zźžż',
  A: 'AÀÁÂÃÄÅĀĄĂ',
  C: 'CÇĆČĈ',
  D: 'DĐĎ',
  E: 'EÈÉÊËĒĘĚĔĖ',
  G: 'GĞĜĢ',
  H: 'HĤĦ',
  I: 'IÌÍÎÏĪĨĬĮİ',
  J: 'JĴ',
  K: 'KĶĸ',
  L: 'LŁĽĹĻĿ',
  N: 'NÑŃŇŅŉŊ',
  O: 'OÒÓÔÕÖØŌŐŎ',
  R: 'RŔŘŖ',
  S: 'SßŚŠŞŜȘ',
  T: 'TŤŢŦȚ',
  U: 'UÙÚÛÜŪŮŰŬŨŲ',
  W: 'WŴ',
  Y: 'YŸÝŶ',
  Z: 'ZŹŽŻ',
}

export function createAccentInsensitiveRegex(search: string): RegExp {
  // Escape special characters in search string.
  // We need to be able to search for special characters like `.` or `+` in the search string.
  const escapedSearch = search.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')

  const regexPattern = escapedSearch
    .split('')
    .map((char) => {
      return ACCENT_MAP[char] ? `[${ACCENT_MAP[char]}]` : char
    })
    .join('')

  return new RegExp(regexPattern, 'i')
}
